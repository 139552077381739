import { MDBCol, MDBContainer, MDBRow } from 'mdbreact'

import BreadcrumbMenu from '../../components/menus/bcmenu'
import Brochures from '../../components/pdfdocs/brochureslist'
import Extrabox from '../../components/extrabox'
import InlineBodyTransform from '../../components/inlinebodytransform'
import Layout from '../../components/layout'
import React from 'react'
import SEO from '../../components/seo'
import SideNavBar from '../../components/menus/navbarmenu'
import Videos from '../../components/videos/brightcovevideoserviceslist'
import { graphql } from 'gatsby'

let gmdv = require(`../../ghc_config`)

/** Fixed Metatags */

const Detail = ({ data, location }) => {
  // console.log(data)
  // console.log(location)
  //  Menu relation
  let menuName = gmdv.getMenuid(location)

  //  Page
  const post = data.nodeOurServices
  let backgroundImage = ''
  if (post.relationships.field_image != null) {
    backgroundImage =
      post.relationships.field_image.localFile.childImageSharp.ourservice.src
    backgroundImage = encodeURI(backgroundImage)
  }
  const boxes = post.field_specific_extra_block_on_rg

  return (
    <Layout>
      <SEO
        title={post.field_met ? post.field_met.title : post.title}
        description={post.field_met ? post.field_met.description : post.title}
        keywords={post.field_met ? [post.field_met.keywords] : [post.title]}
      />
      <main>
        <MDBContainer tag="section" id="maincontent">
          <MDBRow center>
            <MDBCol xs="12" md="3" lg="3" className="leftside">
              <SideNavBar menuName={menuName} location={location} />
            </MDBCol>
            <MDBCol xs="12" md="9" lg="9" className="col-12 contentRight">
              <h1
                className="pagetitle"
                style={{ backgroundImage: `url(${backgroundImage})` }}
              >
                {post.title}
              </h1>
              <BreadcrumbMenu menuName={menuName} location={location} />
              {((post.relationships.field_brochures.length > 0 &&
                !post.relationships.field_brochures[0].field_description) ||
                post.relationships.field_brochures.length === 0) &&
              (!boxes || boxes.length === 0) ? (
                <MDBRow center>
                  <MDBCol xs="12" md="12" lg="12" className="col-12 px-3">
                    <InlineBodyTransform bodyValue={post.body} />
                    <Videos data={post} />
                  </MDBCol>
                </MDBRow>
              ) : (
                <MDBRow center>
                  <MDBCol xs="12" md="12" lg="8" className="col-12 px-3">
                    <InlineBodyTransform bodyValue={post.body} />
                    <Videos data={post} />
                  </MDBCol>
                  <MDBCol xs="12" md="12" lg="4" className="col-12 px-3">
                    <Brochures data={post} />
                    {boxes && boxes.length > 0 ? (
                      <Extrabox extrabox={boxes} />
                    ) : null}
                  </MDBCol>
                </MDBRow>
              )}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </main>
    </Layout>
  )
}

export default Detail

export const query = graphql`
  query($id: String!) {
    nodeOurServices(id: { eq: $id }) {
      title
      body {
        value
      }
      field_specific_extra_block_on_rg {
        value
      }
      field_met {
        title
        description
        keywords
      }
      created
      relationships {
        field_image {
          localFile {
            childImageSharp {
              ourservice: original {
                src
              }
            }
          }
        }
        field_video {
          field_title
          field_video_description {
            value
          }
          field_brightcovevideo_id
          field_brightcovevideo_link
          relationships {
            field_thumbnailurl {
              localFile {
                childImageSharp {
                  thumbnail: original {
                    src
                  }
                }
              }
            }
          }
        }
        field_brochures {
          field_description {
            value
          }
          field_category
          relationships {
            field_image_icon {
              localFile {
                childImageSharp {
                  imageIcon: gatsbyImageData(layout: FIXED, width: 64)
                }
              }
            }
            field_pdf {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }

    # Menu relation
    allMenuLinkContentMenuLinkContent {
      edges {
        node {
          title
          link {
            uri
          }
          menu_name
        }
      }
    }
  }
`
